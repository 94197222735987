.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding: 0 72px 42px;
}

.footer__container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.footer__logos-block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer__logo {
  width: 248px;
  height: 75px;
}

.footer__powered-box {
  display: flex;
  align-items: center;
  gap: 17px;
}

.footer__powered-text {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.17;
}

.footer__powered-logo {
  width: 154px;
  height: 41px;
}

.footer__powered-logo-fill {
  fill: var(--text-color);
}

.footer__links-block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid var(--border-color);
  box-sizing: border-box;
  padding-top: 20px;
}

.footer__links-list {
  display: flex;
  gap: 42px;
}

.footer__link {
  color: var(--secondary-neutral-color);
  font-size: 28px;
  font-weight: 400;
  line-height: 1.17;
  letter-spacing: 0.281px;
  transition: all 0.2s ease-in-out;
}

.footer__link:hover {
  opacity: 0.7;
}

.footer__social-list {
  display: flex;
  gap: 30px;
}

.footer__social-link {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}

.footer__social-link:hover {
  opacity: 0.7;
}

.footer__social-icon {
  width: 100%;
}

.footer__social-icon-fill {
  fill: var(--text-color);
}

.footer__bg {
  display: none;
}

@media (max-width: 1300px) {
  .footer {
    padding: 0 32px 42px;
  }

  .footer__links-list {
    gap: 30px;
  }

  .footer__link {
    font-size: 26px;
  }
}

@media (max-width: 900px) {
  .footer {
    padding: 0 0 24px;
  }

  .footer__logos-block {
    justify-content: center;
  }

  .footer__logo {
    display: none;
  }

  .footer__links-block {
    flex-direction: column-reverse;
    gap: 28px;
    border-top: none;
    padding-top: 0;
  }

  .footer__links-list {
    width: 100%;
    justify-content: center;
    gap: 27px;
    border-top: 2px solid var(--border-color);
    box-sizing: border-box;
    padding-top: 20px;
  }

  .footer__link {
    font-size: 18px;
    letter-spacing: 0.177px;
  }

  .footer__link:hover {
    opacity: 1;
  }

  .footer__social-list {
    gap: 38px;
  }

  .footer__social-link {
    width: 35px;
    height: 35px;
  }

  .footer__social-link:hover {
    opacity: 1;
  }

  .footer__bg {
    display: block;
    width: 1679px;
    height: 932px;
    object-fit: cover;
    position: absolute;
    top: -106px;
    z-index: -1;
  }
}
