@font-face {
    font-family: 'Rubik';
    src: url('Rubik-Light_1.eot');
    src: url('Rubik-Light_1.eot?#iefix') format('embedded-opentype'),
        url('Rubik-Light_1.woff2') format('woff2'),
        url('Rubik-Light_1.woff') format('woff'),
        url('Rubik-Light_1.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('Rubik-Regular.eot');
    src: url('Rubik-Regular.eot?#iefix') format('embedded-opentype'),
        url('Rubik-Regular.woff2') format('woff2'),
        url('Rubik-Regular.woff') format('woff'),
        url('Rubik-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('Rubik-Medium.eot');
    src: url('Rubik-Medium.eot?#iefix') format('embedded-opentype'),
        url('Rubik-Medium.woff2') format('woff2'),
        url('Rubik-Medium.woff') format('woff'),
        url('Rubik-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('Rubik-SemiBold.eot');
    src: url('Rubik-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Rubik-SemiBold.woff2') format('woff2'),
        url('Rubik-SemiBold.woff') format('woff'),
        url('Rubik-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('Rubik-Bold.eot');
    src: url('Rubik-Bold.eot?#iefix') format('embedded-opentype'),
        url('Rubik-Bold.woff2') format('woff2'),
        url('Rubik-Bold.woff') format('woff'),
        url('Rubik-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('Rubik-ExtraBold.eot');
    src: url('Rubik-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Rubik-ExtraBold.woff2') format('woff2'),
        url('Rubik-ExtraBold.woff') format('woff'),
        url('Rubik-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('Rubik-Black.eot');
    src: url('Rubik-Black.eot?#iefix') format('embedded-opentype'),
        url('Rubik-Black.woff2') format('woff2'),
        url('Rubik-Black.woff') format('woff'),
        url('Rubik-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
