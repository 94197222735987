.token {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding: 160px 0 48px;
}

.token__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  border-radius: 35px;
  background: linear-gradient(
    90deg,
    var(--container-bg) 0%,
    var(--dark-gradient-color) 89.5%
  );
}

.token__content {
  width: 100%;
  max-width: 1344px;
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding: 103px 72px;
}

.token__text-block {
  width: 50%;
  max-width: 580px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.token__title {
  font-size: 52px;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.519px;
  text-transform: uppercase;
}

.token__text {
  color: var(--neutral-color);
  font-size: 36px;
  font-weight: 300;
  line-height: 1.35;
  letter-spacing: 0.422px;
  padding: 17px 0 37px;
}

.token__img-block {
  width: 72.17%;
  max-width: 866px;
  height: 100%;
  position: absolute;
  top: 0;
  right: -26px;
  z-index: 0;
}

.token__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.token__img-blackout {
  width: 15%;
  height: 134%;
  position: absolute;
  border-radius: 100%;
  background: var(--dark-gradient-color);
  filter: blur(63.3px);
}

.token__img-blackout_position_top {
  display: none;
}

.token__img-blackout_position_right {
  top: -11.62%;
  right: -7.85%;
}

.token__img-blackout_position_left {
  top: -11.62%;
  left: -1.39%;
}

.token__bg {
  width: 127.76%;
  min-width: 1679px;
  height: 1362px;
  object-fit: cover;
  position: absolute;
  top: -150px;
  z-index: -1;
}

@media (max-width: 1300px) {
  .token {
    padding: 140px 0 48px;
  }

  .token__content {
    padding: 83px 72px;
  }

  .token__title {
    font-size: 36px;
  }

  .token__text {
    font-size: 26px;
  }

  .token__img-block {
    width: 90%;
    right: -66px;
  }

  .token__img {
    object-position: 0 85%;
  }

  .token__bg {
    height: 1250px;
  }
}

@media (max-width: 900px) {
  .token {
    padding: 54px 0 41px;
  }

  .token__content {
    flex-direction: column;
    padding: 46px 34px;
  }

  .token__text-block {
    width: 100%;
    max-width: unset;
    align-items: center;
  }

  .token__title {
    text-align: center;
    font-size: 36px;
    letter-spacing: 0.362px;
    text-transform: unset;
  }

  .token__text {
    text-align: center;
    font-size: 23px;
    letter-spacing: 0.285px;
    padding: 17px 0 204px;
  }

  .token__img-block {
    width: unset;
    max-width: unset;
    height: 264px;
    top: 222px;
    right: unset;
  }

  .token__img-blackout {
    width: 58px;
    height: 354px;
    filter: blur(28.2px);
  }

  .token__img-blackout_position_top {
    display: block;
    width: 126px;
    height: 462px;
    transform: rotate(90deg);
    filter: blur(25.4px);
    left: 142px;
    top: -241px;
  }

  .token__img-blackout_position_right {
    top: -31px;
    right: -30px;
  }

  .token__img-blackout_position_left {
    top: -31px;
    left: 5px;
  }

  .token__bg {
    height: 932px;
    top: -392px;
  }
}
