.pros {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding: 195px 62px 0;
}

.pros__container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  position: relative;
  z-index: 1;
}

.pros__title {
  text-align: center;
  font-size: 80px;
  font-weight: 800;
  line-height: 1.17;
  letter-spacing: 0.801px;
}

.pros__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.pros__item-card {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(min-content, max-content) 33% minmax(
      min-content,
      max-content
    );
  align-items: center;
  justify-items: start;
  gap: 12px;
  border-radius: 35px;
  background: var(--container-bg);
  box-sizing: border-box;
  padding: 46px 11px 46px 6px;
}

.pros__card-img {
  width: 243px;
}

.pros__card-title {
  text-align: center;
  font-size: 49px;
  font-weight: 800;
  line-height: 1.17;
  letter-spacing: 0.495px;
  text-transform: uppercase;
}

.pros__card-text {
  max-width: 544px;
  font-size: 35px;
  font-weight: 400;
  line-height: 1.65;
  letter-spacing: 0.495px;
}

.pros__bg {
  width: 127.76%;
  min-width: 1679px;
  height: 1362px;
  object-fit: cover;
  position: absolute;
  top: 0;
  z-index: -1;
}

.pros__dices-img {
  width: 11.04%;
  transform: rotate(131.62deg);
  position: absolute;
  top: 1102px;
  left: -1.41%;
  z-index: 0;
}

.pros__jelly-img {
  width: 38.02%;
  position: absolute;
  top: 467px;
  right: -14.11%;
  z-index: 0;
}

@media (max-width: 1300px) {
  .pros {
    padding: 165px 32px 0;
  }

  .pros__title {
    font-size: 60px;
  }

  .pros__card-img {
    width: 162px;
  }

  .pros__card-title {
    font-size: 38px;
  }

  .pros__card-text {
    font-size: 24px;
  }

  .pros__bg {
    height: 1250px;
  }

  .pros__dices-img {
    top: unset;
    bottom: -66px;
    transform: rotate(-159.186deg);
  }

  .pros__jelly-img {
    top: 187px;
  }
}

@media (max-width: 900px) {
  .pros {
    padding: 80px 62px 0;
  }

  .pros__container {
    gap: 22px;
  }

  .pros__title {
    width: calc(100% + 36px);
    font-size: 36px;
    letter-spacing: 0.362px;
    margin-left: -18px;
  }

  .pros__list {
    gap: 20px;
  }

  .pros__item-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    padding: 19px 16px;
  }

  .pros__card-img {
    width: 114px;
    margin-top: -29px;
    margin-left: -24px;
  }

  .pros__card-title {
    font-size: 24px;
    letter-spacing: 0.236px;
  }

  .pros__card-text {
    max-width: 100%;
    font-size: 16px;
    letter-spacing: 0.23px;
  }

  .pros__bg {
    height: 932px;
    top: 35px;
  }

  .pros__dices-img {
    width: 95px;
    bottom: -32px;
    left: -4px;
  }

  .pros__jelly-img {
    width: 186px;
    top: 104px;
    right: -61px;
  }
}
