.game-card {
  width: 440px;
  aspect-ratio: 44/25;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  box-sizing: border-box;
  padding: 32px;
}

.game-card__return {
  width: fit-content;
  border-radius: 8px;
  background: var(--opacity-bg);
  box-sizing: border-box;
  padding: 6px 12px;
}

.game-card__return-text {
  color: var(--accent-color);
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.game-card__text-block {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.game-card__title {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.25;
}

.game-card__card-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
}

.game-card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: right;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

@media (max-width: 1300px) {
  .game-card {
    width: 380px;
    padding: 24px;
  }
}

@media (max-width: 900px) {
  .game-card {
    width: 268px;
    border-radius: 15px;
    padding: 19px;
  }

  .game-card__return {
    border-radius: 5px;
    padding: 4px 7px;
  }

  .game-card__return-text {
    font-size: 7px;
    letter-spacing: 0.219px;
  }

  .game-card__text-block {
    gap: 4px;
  }

  .game-card__title {
    font-size: 19px;
  }

  .game-card__card-text {
    font-size: 10px;
  }
}
