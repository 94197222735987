.join-btn {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: var(--accent-color);
  box-shadow: 0px -1px 0px 0px var(--shadow-color) inset;
  box-sizing: border-box;
  padding: 31px 84px 30px 85px;
  transition: all 0.2s ease-in-out;
}

.join-btn:hover {
  opacity: 0.8;
}

.join-btn__text {
  color: var(--text-on-contrast-color);
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.17;
}

@media (max-width: 1300px) {
  .join-btn {
    padding: 30px 64px;
  }

  .join-btn__text {
    font-size: 22px;
  }
}

@media (max-width: 900px) {
  .join-btn {
    border-radius: 16px;
    padding: 24px 67px;
  }

  .join-btn:hover {
    opacity: 1;
  }

  .join-btn__text {
    font-size: 19px;
  }
}
