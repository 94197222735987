.games {
  width: 100%;
  position: relative;
  margin-top: 120px;
  z-index: 1;
}

.games__container_mobile {
  display: none;
}

@media (max-width: 900px) {
  .games {
    margin-top: 42px;
  }

  .games__container {
    display: none;
  }

  .games__container_mobile {
    display: block;
  }
}
