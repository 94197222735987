.scheme {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 166px 72px 0;
}

.scheme__title {
  text-align: center;
  font-size: 80px;
  font-weight: 800;
  line-height: 1.17;
  letter-spacing: 0.801px;
}

.scheme__subtitle {
  color: var(--neutral-color);
  text-align: center;
  font-size: 52px;
  font-weight: 300;
  line-height: 1.17;
  letter-spacing: 0.519px;
  padding-top: 16px;
}

.scheme__img {
  width: 100%;
  max-width: 1200px;
  margin-top: 65px;
}

@media (max-width: 1300px) {
  .scheme {
    padding: 146px 32px 0;
  }

  .scheme__title {
    font-size: 60px;
  }

  .scheme__subtitle {
    font-size: 40px;
  }
}

@media (max-width: 900px) {
  .scheme {
    padding: 87px 14px 0;
  }

  .scheme__title {
    width: 63%;
    font-size: 36px;
    letter-spacing: 0.362px;
  }

  .scheme__subtitle {
    width: 63%;
    font-size: 23px;
    letter-spacing: 0.234px;
    padding-top: 8px;
  }

  .scheme__img {
    margin-top: 44px;
  }
}
