.games-swiper {
  box-sizing: border-box;
  padding: 0 10px !important;
  margin-top: 18px;
}

.games-swiper:first-of-type {
  margin-top: 0;
  margin-left: -156px;
}

.swiper-slide {
  width: fit-content !important;
}

@media (max-width: 900px) {
  .games-swiper {
    padding: 0 !important;
    margin-top: 12px;
  }

  .games-swiper:first-of-type {
    margin-left: -92px;
  }

  .games-swiper:last-of-type {
    margin-left: -174px;
  }
}
