@import url(./assets/fonts/Rubic/stylesheet.css);

body {
  margin: 0;
  font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  background: var(--website-bg);
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, h1, h2, h3, h4, h5, h6, blockquote, span {
  font-style: normal;
  padding: 0;
  margin: 0;
  white-space: pre-line;
}

a {
  font-style: normal;
  text-decoration: none;
  color: var(--text-color);
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  color: var(--text-color);
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

ul, ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  object-fit: contain;
  pointer-events: none;
  user-select: none;
}

:root {
  --website-bg: #171821;
  --container-bg: #13151B;
  --text-color: #FFF;
  --text-on-contrast-color: #000;
  --neutral-color: #BABABA;
  --secondary-neutral-color: #40404A;
  --accent-color: #FFE70A;
  --secondary-accent-color: #C661F4;
  --dark-color: #10175D;
  --secondary-dark-color: #131849;
  --shadow-color: #403B0E;
  --opacity-bg: rgba(57, 52, 71, 0.25);
  --border-color: #43434D;
  --dark-gradient-color: #0A0E1A;
}
