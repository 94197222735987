.heading {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding: 326px 120px 0;
}

.heading__container {
  width: 100%;
  max-width: 780px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
}

.heading__logo {
  width: 294px;
  height: 89px;
}

.heading__title {
  text-align: center;
  font-size: 80px;
  font-weight: 800;
  line-height: 1.17;
  letter-spacing: 0.801px;
  padding-top: 10px;
}

.heading__join-btn-box {
  display: flex;
  position: relative;
  margin-top: 52px;
}

.heading__btn-sticker {
  width: 109px;
  height: 109px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -50px;
  right: -42px;
}

.heading__sticker-icon {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.heading__sticker-icon-fill {
  fill: var(--secondary-accent-color);
}

.heading__sticker-text {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.17;
  transform: rotate(14.59deg);
}

.heading__powered-box {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 258px;
}

.heading__powered-text {
  text-align: center;
  font-size: 29px;
  font-weight: 600;
  line-height: 1.17;
}

.heading__powered-logo {
  width: 189px;
  height: 51px;
}

.heading__powered-logo-fill {
  fill: var(--text-color);
}

.heading__bg {
  width: 127.76%;
  min-width: 1679px;
  height: 1362px;
  object-fit: cover;
  position: absolute;
  top: -182px;
  z-index: -1;
}

.heading__bg-circle {
  width: 19.79%;
  aspect-ratio: 1/1;
  position: absolute;
  border-radius: 100%;
  filter: blur(212.7px);
  z-index: -2;
}

.heading__bg-circle_position_left {
  top: 462px;
  left: -6.77%;
  background: var(--dark-color);
}

.heading__bg-circle_position_right {
  top: 439px;
  right: -8.7%;
  background: var(--secondary-dark-color);
}

.heading__star-img {
  width: 6.51%;
  position: absolute;
  top: 136px;
  left: 8.44%;
  z-index: 1;
}

.heading__ship-img {
  width: 11.88%;
  transform: rotate(45deg);
  position: absolute;
  top: 380px;
  left: 11.04%;
  z-index: 1;
}

.heading__coins-img {
  width: 13.07%;
  transform: rotate(16.824deg);
  position: absolute;
  bottom: -43px;
  left: -3.07%;
  z-index: 1;
}

.heading__heart-img {
  width: 12.24%;
  transform: rotate(10.855deg);
  position: absolute;
  top: 143px;
  right: 3.68%;
  z-index: 1;
}

.heading__disc-img {
  width: 5.83%;
  transform: rotate(8.843deg);
  position: absolute;
  top: 319px;
  right: 17.55%;
  z-index: 1;
}

.heading__dices-img {
  width: 11.04%;
  transform: rotate(131.62deg);
  position: absolute;
  top: 574px;
  right: 14.32%;
  z-index: 1;
}

.heading__shuttle-box {
  width: 27.66%;
  display: flex;
  position: absolute;
  bottom: -82px;
  right: -2.76%;
  z-index: 1;
}

.heading__shuttle-img {
  width: 100%;
}

.heading__shuttle-blackout {
  width: 55.56%;
  aspect-ratio: 59/29;
  position: absolute;
  right: -97px;
  bottom: -110px;
  right: -18.27%;
  bottom: -44%;
  border-radius: 100%;
  background: var(--website-bg);
  filter: blur(10.2px);
}

@media (max-width: 1300px) {
  .heading {
    padding: 186px 32px 0;
  }

  .heading__title {
    font-size: 60px;
  }

  .heading__powered-box {
    margin-top: 200px;
  }

  .heading__bg {
    height: 1120px;
  }

  .heading__bg-circle_position_left {
    top: 252px;
  }

  .heading__bg-circle_position_right {
    top: 189px;
  }

  .heading__star-img {
    top: 86px;
    left: 3.44%;
  }

  .heading__ship-img {
    top: 280px;
    left: 6.04%;
  }

  .heading__heart-img {
    top: 93px;
    right: 1%;
  }

  .heading__disc-img {
    top: 200px;
    right: 12.55%;
  }

  .heading__dices-img {
    top: 384px;
    right: 7.32%;
  }

  .heading__shuttle-box {
    bottom: -62px;
  }
}

@media (max-width: 900px) {
  .heading {
    padding: 336px 12px 0;
  }

  .heading__logo {
    width: 143px;
    height: 43px;
  }

  .heading__title {
    font-size: 38px;
    letter-spacing: 0.383px;
    padding-top: 8px;
  }

  .heading__join-btn-box {
    margin-top: 32px;
  }

  .heading__btn-sticker {
    width: 87px;
    height: 87px;
    top: -40px;
    right: -34px;
  }

  .heading__sticker-text {
    font-size: 14px;
  }

  .heading__powered-box {
    gap: 12px;
    margin-top: 286px;
  }

  .heading__powered-text {
    font-size: 23px;
  }

  .heading__powered-logo {
    width: 147px;
    height: 39px;
  }

  .heading__bg {
    height: 932px;
    top: 0;
  }

  .heading__bg-circle {
    display: none;
  }

  .heading__star-img {
    width: 70px;
    top: -15px;
    left: -37px;
  }

  .heading__ship-img {
    display: none;
  }

  .heading__coins-img {
    width: 112px;
    top: 245px;
    left: -9px;
  }

  .heading__heart-img {
    width: 235px;
    top: 647px;
    left: -106px;
  }

  .heading__disc-img {
    display: none;
  }

  .heading__dices-img {
    width: 114px;
    top: 249px;
    right: -16px;
  }

  .heading__shuttle-box {
    width: 307px;
    bottom: 57px;
    right: -31px;
  }
}
